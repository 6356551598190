import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.png';
import CImage from '../../images/case-single/img-1.jpg';

const Donate = () => {
  return (
    <Fragment>
      <Navbar hclass={'header-style-1'} Logo={Logo} btnClass={'theme-btn-s1'} />
      <PageTitle pageTitle="Support Our Community" pagesub="Help Us Make a Difference" />
  
      <section className="case-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xl-8 col-lg-7 col-12">
              <div className="img-holder details-img">
                <img src={CImage} alt="" />
              </div>
            </div>
            <div className="col col-xl-4 col-lg-5 col-12">
              <div className="case-info-area">
                <h3>Join Us in Nourishing Our Community</h3>
                <p>At Roslindale Food Collective, we believe in the power of community support to make a difference in times of need. Our mission is to collect and distribute food to those who face food insecurity, ensuring that every member of our community has access to nutritious meals. Your donation today helps us extend our reach, provide more food, and bring hope to families and individuals. Together, we can create a stronger, healthier community for all.</p>
              </div>
            </div>
          </div>
          <div className="row donate-area-bottom">
            <div className="col col-lg-8 col-12">
              <div className="donate-area-wrapper">
                <div style={{ position: 'relative', overflow: 'hidden', height: '1200px', width: '100%' }}>
                  <iframe 
                    title="Donation form powered by Zeffy"
                    style={{ position: 'absolute', border: '0', top: '0', left: '0', bottom: '0', right: '0', width: '100%', height: '100%' }}
                    src="https://www.zeffy.com/embed/donation-form/0c9fb016-0dd9-4d73-bd8d-e7b03fef2de9"
                    allowPaymentRequest
                    allowTransparency="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default Donate;
