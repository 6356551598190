import React from 'react'
import abimg from '../../images/target.jpg'
import { Link } from 'react-router-dom';

const Targetarea = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="target-area section-padding" id="about">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-5 col-12">
                        <div className="img-holder">
                            <img src={abimg} alt=""/>
                        </div>
                    </div>
                    <div className="col col-lg-7 col-12">
                        <div className="target-content">
                            <div className="section-title" ><br /> <br />
                                <span style={{textAlign:'center'}}>Our Mission</span>
                                <h2 style={{textAlign:'center'}}>Empowering the Community <span>Through Food Donation</span></h2>
                            </div>
                            <div className="content center">
                                <p style={{textAlign:'center'}}>For over five years, Roslindale Food Collective, founded by Leah Arteaga, has been a beacon of hope in our community. What started as a small initiative to address food insecurity has grown into a robust network of volunteers dedicated to collecting and donating food to those in need. Every Sunday at 3pm, we come together to make a difference, one box of food at a time. Our journey is a testament to the power of collective effort and the compassion of our volunteers, who have been instrumental in turning Leah's vision into a reality.</p>

                                <div className="goal-raised">
                                    {/* <div>
                                        <span>Donation goal </span>
                                        <h3>$45,000</h3>
                                    </div>
                                    <div>
                                        <span>Donation raised </span>
                                        <h3>$35,000</h3>
                                    </div> */}
                                </div>
                                <Link onClick={ClickHandler} to="/donate" className="theme-btn-s3"><i className="fi flaticon-like"></i> Donate Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Targetarea;
