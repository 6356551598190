import React from 'react'
import { Link } from 'react-router-dom'


const HeaderTopbar = (props) => {
    return (
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 offset-lg-2 col-12">
                        <div className="social">
                            <span>Follow us: </span>
                            <ul>
                            <li>
                                            <a href="https://www.facebook.com/rosifoodcollective" target="_blank">
                                                <i className="ti-facebook"></i>
                                            </a>
                                        </li>
                                {/* <li><a href="https://www.twitter.com" target='_blank'><i className="ti-twitter-alt"></i></a></li>
                                <li><a href="https://www.pinterest.com" target='_blank'><i className="ti-pinterest"></i></a></li> */}
{/* 
                                <li><Link to="www.facebook.com"><i className="ti-facebook"></i></Link></li>
                                <li><Link to="/"><i className=""></i></Link></li>
                                <li><Link to="/"><i className="ti-linkedin"></i></Link></li>
                                <li><Link to="/"><i className=""></i></Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-7 col-12">
                        <div className="link-donate">
                            {/* <ul>
                                <li><Link to="/faq">FAQ</Link></li>
                                <li><Link to="/">Privacy</Link></li>
                            </ul> */}
                            <div className="donate-btn-wrap">
                                <Link to="/donate" className="donate-btn-2">Donate now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;