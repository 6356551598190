import React from 'react';
import ContactFrom from '../ContactFrom';

const Contactpage = (props) => {
    return (
        <div>
            <section className="contact-section section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-lg-8 col-md-10 col-12">
                            <div className="section-title-s3">
                                <span>Connect With Us</span>
                                <h2>We're Here to Help!</h2>
                                <p>At Roslindale Food Collective, our mission is to nourish our community with kindness and respect. We believe in building a stronger, healthier community through food assistance and support. Reach out to see how you can help or to get the support you need.</p>
                            </div>
                        </div>
                        <div className="col col-xs-12 col-12">
                            <div className="contact-info-grids">
                                <div className="grid">
                                    <i className="fi flaticon-house"></i>
                                    <h4>Our Location</h4>
                                    <p>319 Kittredge Street Roslindale, MA 02131</p>
                                </div>
                                <div className="grid">
                                    <i className="fi flaticon-email"></i>
                                    <h4>Email Us</h4>
                                    <p>
                                        <a href="mailto:rosifoodcollective@gmail.com" style={{ color: 'white', textDecoration: 'none' }}>rosifoodcollective@gmail.com</a><br />
                                        {/* <a href="mailto:leah@roslindalefoodcollective.com" style={{ color: 'white', textDecoration: 'none' }}>leah@roslindalefoodcollective.com</a> */}
                                    </p>
                                </div>
                                <div className="grid">
                                    <i className="fi flaticon-call"></i>
                                    <h4>Call Us</h4>
                                    <p>
                                        <a href="tel:+18578290111" style={{ color: 'white', textDecoration: 'none' }}>(857) 829-0111</a><br />
                                        {/* <a href="tel:+18578290111" style={{ color: 'white', textDecoration: 'none' }}>+1 (857)829-0111</a> */}
                                    </p>
                                </div>
                                {/* <div className="grid">
                                    <i className="fi flaticon-alarm"></i>
                                    <h4>Opening Hours</h4>
                                    <p>Sunday <br />03PM - 03:30PM</p>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="contact-form-area">
                        <div className="row">
                            <div className="col col-lg-4 col-12">
                                <div className="contact-text">
                                    <h3>Got Questions? Get in Touch!</h3>
                                    <p>Your support makes our work possible. Whether you want to donate, volunteer, or inquire about our services, please reach out. We're here to answer any questions and provide the information you need.</p>
                                </div>
                            </div>
                            <div className="col col-lg-8 col-12">
                                <ContactFrom />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-map-section">
                <div className="contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5904.11292525905!2d-71.13725378769838!3d42.27731807108059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37edb1e4afa9b%3A0x36435ca41cf5a13e!2s319%20Kittredge%20St%2C%20Roslindale%2C%20MA%2002131!5e0!3m2!1sen!2sus!4v1711493021319!5m2!1sen!2sus" allowfullscreen></iframe>
                </div>
            </section>
        </div>
    )
}

export default Contactpage;
