import React, { useState } from "react";
import { NavLink } from "react-router-dom"; // Import NavLink
import MobileMenu from "../MobileMenu/MobileMenu";
import Logo from "../../images/logonew.svg";
import HeaderTopbar from "../HeaderTopbar";
import { connect } from "react-redux";

const Header2 = (props) => {
  const [menuActive, setMenuState] = useState(false);
  const ClickHandler = () => window.scrollTo(10, 0);

  return (
    <header id="header" className="site-header header-style-3">
      <HeaderTopbar />
      <nav className="navigation navbar navbar-expand-lg navbar-light">
        <div className="container">
          <div className="navbar-header">
            <MobileMenu />
            <NavLink onClick={ClickHandler} className="navbar-brand" to="/home">
              <img src={Logo} style={{ width: "95%" }} alt="" />
            </NavLink>
          </div>
          <div
            id="navbar"
            className="collapse navbar-collapse navigation-holder"
          >
            <ul className="nav navbar-nav mb-2 mb-lg-0">
              <li>
                <NavLink
                  exact
                  onClick={ClickHandler}
                  activeClassName="active"
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={ClickHandler}
                  activeClassName="active"
                  to="/impact"
                >
                  Impact
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={ClickHandler}
                  activeClassName="active"
                  to="/about"
                >
                  About Us
                </NavLink>
              </li>
              {/* <li>
                                <NavLink onClick={ClickHandler} activeClassName="active" to="/causes">
                                    Causes
                                </NavLink>
                            </li> */}
              <li>
                <NavLink
                  onClick={ClickHandler}
                  activeClassName="active"
                  to="/shop"
                >
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={ClickHandler}
                  activeClassName="active"
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps)(Header2);
