import React, { useEffect, useState } from "react";
import glow from "../../images/icon/glow.svg";
import styles from "../../css/timeline.module.css";
import { getReports } from "../../api/sanity";

const Timeline = () => {
  const [timelineData, setTimeLineData] = useState([]);
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        let sum = 0;
        const reports = await getReports();
        const formattedReports = reports
          .map((report) => {
            const formattedDate = new Date(report.date).toLocaleDateString(
              "en-GB",
              {
                day: "numeric",
                month: "long",
                year: "numeric",
              }
            );

            const totalAmount =
              report.produce +
              report.meat +
              report.dairy +
              report.grocerySelfStable +
              report.baked +
              report.prepared +
              report.riceAndBeans +
              report.gleanersProduce;

            sum += totalAmount;
            return {
              date: formattedDate,
              total: totalAmount.toLocaleString(), // Formatear el total con separadores de miles
            };
          })
          .sort((a, b) => new Date(a.date) - new Date(b.date));
          console.log("Último valor calculado:", formattedReports[formattedReports.length - 1]); // Mostrar el último valor en consola

        setTotalSum(sum.toLocaleString()); // Formatear también el totalSum
        setTimeLineData(formattedReports);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    fetchData();
  }, []);

  const calculateLineWidth = () => {
    const baseWidth = 100; // Ancho base de la línea
    const extraWidth = 270; // Ancho extra por cada semana (ajústalo según tu diseño)
    
    console.log("Cantidad de semanas en timeline:", timelineData.length); // Verifica cuántas semanas se están cargando
  
    // Calculamos el ancho total en base al número de semanas
    return `${timelineData.length * extraWidth + baseWidth}px`;
  };
  
  console.log("Timeline data:", timelineData);
console.log("Ancho calculado para la línea:", calculateLineWidth());


  return (
    <section className={styles.timelineSection}>
      <div className={styles.timelineHeader}>
        <h2 className="text-center mb-2">
          2024 donation progress:{" "}
          <span style={{ color: "#2A8B44" }}>{totalSum}</span> lbs donated so far...
        </h2>
      </div>
      <div className={`${styles.timelineWrapper} bg-white pt-36 pb-16 lg:pb-0 lg:pt-16`}>
        <div className={styles.timelineInner}>
          <div className="position-relative">
            <div
              className={`${styles.timelineLine} position-absolute bg-success top-50 translate-middle-y rounded`}
              style={{
                width: calculateLineWidth(), // Ajuste dinámico del ancho
              }}
            ></div>
            <div className="d-flex gap-4 pt-1">
              {timelineData.map((item, index) => (
                <div
                  key={index}
                  style={{ minWidth: "270px" }}
                  className="d-flex flex-column align-items-center position-relative"
                >
                  {index % 2 === 0 ? (
                    <div className={`${styles.glowTop} position-absolute`}>
                      <img src={glow} alt="" className="w-16" style={{ transform: "rotate(180deg)" }} />
                    </div>
                  ) : (
                    <div
                      className={`${styles.glowBottom} position-absolute`}
                      style={{
                        top: "120px",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <img src={glow} alt="" className="w-16" />
                    </div>
                  )}
                  {index % 2 === 0 ? (
                    <>
                      <h2 className={`${styles.timelineDate} text-success fs-6 font-normal`}>{item.date}</h2>
                      <div
                        className={`${styles.timelineContent} w-48 h-28 px-4 rounded-pill mx-4 d-flex justify-content-center align-items-center`}
                      >
                        <div className="text-center">
                          <h2 className="fs-4 fw-bold text-white mb-1">{item.total}</h2>
                          <p className="fs-6 text-white">lbs</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`${styles.timelineContentReverse} w-44 h-28 px-4 rounded-pill mx-4 d-flex justify-content-center align-items-center`}
                      >
                        <div className="text-center">
                          <h2 className="fs-4 fw-bold text-white mb-1">{item.total}</h2>
                          <p className="fs-6 text-white">lbs</p>
                        </div>
                      </div>
                      <h2 className={`${styles.dateReverse} text-success fs-6 font-normal`}>{item.date}</h2>
                    </>
                  )}
                  {index < timelineData.length - 1 && (
                    <div className={`${styles.whiteConnector} position-absolute top-50 translate-middle-y`}>
                      <div className={`${styles.circleConnector} rounded-circle`}></div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
