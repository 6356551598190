import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            // Sending the entire forms object as it matches the template placeholders
            emailjs.send('service_1y9xxvb', 'template_ey6hl3c', forms, 'ieYSuJRWZ6vJkVgXW')
                .then((result) => {
                    alert('Message sent successfully!');
                    console.log(result.text);
                    // Reset form fields after successful submission
                    setForms({
                        name: '',
                        email: '',
                        subject: '',
                        phone: '',
                        message: ''
                    });
                }, (error) => {
                    alert('Failed to send the message, please try again.');
                    console.log(error.text);
                });
        } else {
            validator.showMessages();
        }
    };

    return (
        <form className="contact-validation-active" id="contact-form-main" onSubmit={submitHandler}>
            <div className="form-field">
                <input
                    value={forms.name}
                    type="text"
                    name="name"
                    className="form-control"
                    onBlur={changeHandler}
                    onChange={changeHandler}
                    placeholder="Name*"
                />
                {validator.message('name', forms.name, 'required|alpha_space')}
            </div>
            <div className="form-field">
                <input
                    value={forms.email}
                    type="email"
                    name="email"
                    className='form-control'
                    onBlur={changeHandler}
                    onChange={changeHandler}
                    placeholder="Email*"
                />
                {validator.message('email', forms.email, 'required|email')}
            </div>
            <div className="form-field">
                <input
                    value={forms.phone}
                    type="tel"
                    name="phone"
                    className='form-control'
                    onBlur={changeHandler}
                    onChange={changeHandler}
                    placeholder="Your phone"
                />
                {validator.message('phone', forms.phone, 'required|phone')}
            </div>
            <div className="form-field">
                <input
                    value={forms.subject}
                    type="text"
                    name="subject"
                    className="form-control"
                    onBlur={changeHandler}
                    onChange={changeHandler}
                    placeholder="Subject"
                />
                {validator.message('subject', forms.subject, 'required|alpha_space')}
            </div>
            <div className="form-field fullwidth">
                <textarea
                    onBlur={changeHandler}
                    onChange={changeHandler}
                    value={forms.message}
                    name="message"
                    className='form-control'
                    placeholder="Message..."
                />
                {validator.message('message', forms.message, 'required')}
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn-s6">
                    <i className="fi flaticon-like"></i> Submit It Now
                </button>
            </div>
        </form> //update new
    );
}

export default ContactForm;
