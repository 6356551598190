import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ts1 from '../../images/testimonials/img-1.jpg'
// import ts2 from '../../images/testimonials/img-2.jpg'
// import ts3 from '../../images/testimonials/img-3.jpg'

const Testimonial2 = (props) => {

    var settings = {
        dots: false,
        arrows: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,

        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const testimonial = [
        {
            // tsImg: ts1,
            Des: "I've been with the Roslindale Food Collective (RFC) for over 2 years, deeply enjoying the chance to help those facing food insecurity. Led by the inspiring Leah Arteaga, our team works collaboratively together every week to manage and distribute several thousand of pounds of food donations to the community. This experience every week enriches my life, making me feel appreciated and positive about the lives we are touching. I cherish our Sundays together and I’m hopeful for RFC's future growth and continued impact on more families in the community.",
            Title: 'Chris Ocnean',
            Sub: "Volunteer",
        },
        {
            // tsImg: ts1,
            Des: "As a long term volunteer off and on with the Sunday surplus food effort at the Lutheran church on Center St in JP, and previously at the Irving School I have seen the generous dedication of Leah Arteaga and other volunteers in persisting in this always evolving effort.  The way it is organized gives lots of room for volunteer autonomy with just enough structure to make it happen, no easy task. I come to the program first as a way to address food waste and second food insecurity.",
            Title: 'Ronald Beland',
            Sub: "Volunteer",
        },

        {
            // tsImg: ts1,
            Des: "I just want to say how grateful I am to Leah and her helpers for helping out myself, my daughter & mom. I'm a single mom who's struggling daily. Leah's golden heart helps out so many people in the community. There should be more people like her, she's really a gem!",
            Title: 'L.G.',
            Sub: "Beneficiary",
        },
        {
            // tsImg: ts1,
            Des: "I started volunteering 15 months ago and was struck by the big impact the program makes on those served. In a short period of time and with incredible teamwork, healthy food is unloaded, sorted, and assembled to distribute to the community. I enjoy working side by side with everyone and have built new friendships. Its been a rewarding experience. I am happy to be part of a group making a difference in the community.",
            Title: 'B.W.',
            Sub: "Volunteer",
        },
        {
            // tsImg: ts1,
            Des: "Being part of the Roslindale Food Collective has helped me build connections in my community, help those who must work longer to afford inflating grocery prices, and learn how neighbors can mobilize to reduce food waste. It gives me peace to know I can help others each week.",
            Title: 'Buddy',
            Sub: "Beneficiary turned to Volunteer.",
        },
        {
            // tsImg: ts1,
            Des: "Being a part of the Roslindale food program has been a wonderful experience for me, to be able to serve my community is such an honor. We have built a great team of volunteers and it truly feels like family, I am a resident of Roslindale and it's a great place to live. I have been blessed to help my community and to become a volunteer for the Roslindale Food Collective which has taken a special place in my heart.",
            Title: 'Janet N.',
            Sub: "Volunteer",
        }
    ]

    return (

        <section className="testimoninals-funfact-section testimonials-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div className="section-title-s3">
                            <span>#Testimonials</span>
                            <h2>Community Voices</h2>
                            <p>At Roslindale Food Collective, we believe in the power <br />of community support to overcome challenges. Here <br />are some voices from those who've joined us in this amazing journey. Together, we’re making a difference.</p>
                        </div>
                    </div>
                    <div className="testimonials-slider testimonials-slider-area">
                        <Slider {...settings}>
                            {testimonial.map((tesmnl, tsm) => (
                                <div className="grid" key={tsm}>
                                    <div className="author">
                                        <div className="author-img">
                                            {/* <img src={tesmnl.tsImg} alt="" /> */}
                                            <br /><br /><br />
                                        </div>
                                        <h5>{tesmnl.Title}</h5>
                                        <span>{tesmnl.Sub}</span>
                                    </div>
                                    <p>{tesmnl.Des}</p>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial2;