import React from "react";

const MissionSection = (props) => {

    return (
        <section className="mission-vision-section">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="mission-vision-grids clearfix">
                            <div className="grid">
                                <div className="overlay"></div>
                                <h3 style={{textAlign:'center'}}>Our Mission</h3>
                                <p style={{textAlign:'center'}}>Reduce food waste and combat food insecurity by nurturing a network of food distribution that brings sustenance and dignity to our community, ensuring no one is goes hungry.</p>
                            </div>
                            <div className="grid">
                                <div className="overlay"></div>
                                <h3 style={{textAlign:'center'}}>Our Vision</h3>
                                <p style={{textAlign:'center'}}>Envisioning a world where community support and access to nutritious food can actually transform lives, making health and happiness accessible to all.</p>
                            </div>
                            <div className="grid">
                                <div className="overlay"></div>
                                <h3 style={{textAlign:'center'}}> Our Values</h3>
                                <p style={{textAlign:'center'}}>Grounded in not just empathy and support, but also hope, we believe in the power of community to enact change, driving our mission forward with every box of food shared.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MissionSection;
