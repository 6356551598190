import React, { Fragment } from "react";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import CTA from "../../components/Impact/CTA";
import Timeline from "../../components/Impact/Timeline";
import DonationTracker from "../../components/Impact/DonationTracker";

const ImpactPage = () => {
  return (
    <Fragment>
      <Navbar hclass={"header-style-1"} Logo={Logo} btnClass={"theme-btn-s1"} />
      <PageTitle pageTitle={"Impact"} pagesub={"Impact"} />
      <DonationTracker/>
      <Timeline/>
      <CTA/>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ImpactPage;
