import React from "react";
import impactbg from "../../images/impact/impactimg.png";
import styles from "../../css/impact.module.css";

const DonationTracker = (props) => {
  return (
    <section className={styles.donationtrackerpadding} id="about">
      <div className="container">
        <div className="row mb-4"> {/* Added margin-bottom for gap */}
          <div className="col col-lg-6 col-12">
            <div className="img-holder">
              <img src={impactbg} alt="" />
            </div>
          </div>
          <div className="col col-lg-6 col-12">
            <div className="target-content">
              <div className="section-title">
                <br /> <br />
                <span style={{ textAlign: "" }}>
                  Weekly Donation Tracker
                </span>
                <h2 style={{ textAlign: "" }}>
                  Monitoring Our Collective <span>Impact Week by Week</span>
                </h2>
              </div>
              <div className="content ">
                <p style={{ textAlign: "" }}>
                  At Roslindale Food Collective, our mission is to support our
                  community by providing fresh food and produce to those in
                  need. Since January 1st, we have been tracking the pounds of
                  food donated each week, ensuring transparency and showcasing
                  our collective effort along with Food Rescue US that supports
                  many of our rescues every week. This interactive timeline
                  allows you to see our weekly progress and the impact RFC has
                  made to reduce food waste in our community. Join us in making
                  a difference and help spread the word about our mission. Every
                  pound counts, and together, we can fight hunger and build a
                  stronger community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DonationTracker;
