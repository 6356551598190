import React from "react";
import { Link } from "react-router-dom";
import hero1 from "../../images/slider/slide-1.jpg";
// import VideoModal from "../ModalVideo";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Hero = () => {
  return (
    <section className="hero-slider hero-style-1">
      <div
        className="slide-inner slide-bg-image"
        style={{ backgroundImage: `url(${hero1})` }}
      >
        <div className="container">
          <div className="slide-title">
            <h2>
              Join <span>Roslindale Food Collective</span> in Our Mission
            </h2>
          </div>
          <div className="slide-text">
            <p>
              We're a dedicated 100% volunteer led nonprofit collecting and
              donating food to those in need. Be part of our weekly event every
              Sunday afternoon. Together, we can make a difference.
            </p>
          </div>
          <div className="slide-btns">
            <Link onClick={ClickHandler} to="/donate" className="theme-btn-s2">
              Donate Now <i className="fi flaticon-heart-1"></i>
            </Link>
          </div>
          {/* <div className="video-btns">
                        <VideoModal />
                    </div> */}
        </div>
      </div>
      <div className="social">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/rosifoodcollective"
              target="_blank"
              rel="noopener noreferrer"
              onClick={ClickHandler}
            >
              <i className="ti-facebook"></i>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Hero;
