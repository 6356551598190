import React from 'react'

const About4 = (props) => {

    return (
        <section className="about-page-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-8 col-md-10">
                        <div className="section-title-s3">
                            <span>#About us</span>
                            <h2>When <span>hope is hungry</span>, every helping hand feeds it.</h2>
                            <p>Roslindale Food Collective is more than just a food program; it's a movement powered by community spirit and a belief that no one should go hungry. Founded by Leah Arteaga and grown through the hearts and hands of countless volunteers, we've been combatting food insecurity in Roslindale and beyond for over five years. Every Sunday we gather to distribute not just boxes of food, but hope, understanding and the foundation of a community that cares.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
    <div className="col col-xs-12">
        <div className="about-features-grid">
            <div className="grid">
                <div className="icon">
                    <i className="fi flaticon-donation"></i>
                </div>
                <h3>Donation</h3>
                <p>Your contributions support our goal to end food scarcity. Each donation is a step towards a community where everyone has enough.</p>
            </div>
            <div className="grid">
                <div className="icon">
                    <i className="fi flaticon-fruits"></i>
                </div>
                <h3>Food Distribution</h3>
                <p>We gather weekly to provide fresh, nutritious food. Our collective effort ensures that no one in our community goes hungry.</p>
            </div>
            <div className="grid">
                <div className="icon">
                    <i className="fi flaticon-charity"></i>
                </div>
                <h3>Community</h3>
                <p>At our core is a network of volunteers and donors passionate about making a positive change, one box of food and one smile at a time.</p>
            </div>
            {/* <div className="grid">
                <div className="icon">
                    <i className="fi flaticon-charity"></i>
                </div>
                <h3>Support</h3>
                <p>We provide a backbone of support for those in need, ensuring everyone has access to necessary resources to live well.</p>
            </div> */}
            <div className="grid">
                <div className="icon">
                    <i className="fi flaticon-smile"></i>
                </div>
                <h3>Hope</h3>
                <p>Our mission goes beyond food; we aim to inspire hope and create brighter futures through care, sharing, and support.</p>
            </div>
            <div className="grid">
                <div className="icon">
                    <i className="fi flaticon-like"></i>
                </div>
                <h3>Empathy</h3>
                <p>Empathy drives us to understand and address the challenges of those we serve, uniting us in our mission for a better world.</p>
            </div>
        </div>
    </div>
</div>

            </div>
        </section>
    )
}

export default About4;
