import React from "react";
import { Link } from "react-router-dom";
import styles from "../../css/impact.module.css";

const CTA = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className={styles.ctacontainer}>
      <div className={styles.bgimg}>
        <div className="">
          <h2 className="text-center mb-3">Make a Difference Today</h2>
          <p className="text-center mb-4 px-5">
            Your support is crucial to our mission. By donating to Roslindale
            Food Collective, you help us provide essential food and produce to
            those who need it most. Click the button below to contribute and be
            a part of our journey to fight hunger and nourish our community.
            Together, we can make a lasting impact.
          </p>
          <div className="d-flex justify-content-center">
            <Link onClick={ClickHandler} to="/donate" className="theme-btn-s9">
              <i className="fi flaticon-like"></i> Donate Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
