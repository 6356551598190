import atimg1 from '../images/team/img-1.jpg'
import atimg2 from '../images/team/img-2.jpg'
import atimg3 from '../images/team/img-3.jpg'
// import atimg4 from '../images/team/img-4.jpg'
// import atimg5 from '../images/team/img-5.jpg'
// import atimg6 from '../images/team/img-6.jpg'

const Team = [
   {
      Id: '1',
      AtImg: atimg1,
      name: 'Leah Arteaga',
      slug: 'leah-arteaga',
      title: 'Founder',
      description: 'Leah has been with us since the beginning, organizing food drives and community outreach programs.',
      linkedin: 'https://www.linkedin.com/in/leah-arteaga-7b1b1b1b1/',
   },
   {
      Id: '2',
      AtImg: atimg2,
      name: 'Chris Ocnean',
      slug: 'chris-ocnean',
      title: 'Volunteer for 4 years ',
      description: 'My name is Chris Ocnean and I have been volunteering at the Roslindale Food Collective (RFC) full time for about 1 year and have been volunteering at RFC for nearly 2 years.  I find the work incredibly enriching, as I truly enjoy helping others.  The RFC program has given me a convenient outlet to work with a strong, caring team, under an incredible leader (Leah Arteaga), to help those with food insecurities.  We work collaboratively to unload cars that bring food to the program, weighing each box that comes in, then create boxes for the community.  This weekly experience truly enriches my life as I feel so positive after the we have cleaned up, and the program is done.  Although each week is a little different, we are successful due to our fearless leader, Leah Arteaga, who plans and coordinates the food deliveries, volunteers, donations and overall activities for the program every week.  I feel appreciated while I volunteer at the program, and I look forward to working with the other volunteers every Sunday.  I hope the RFC program is able to continue for years to come, is able to expand, and can help as many families as possible.',
      linkedin: 'https://www.linkedin.com/in/chris-ocnean-7b1b1b1b1/',
   },
   {
      Id: '3',
      AtImg: atimg3,
      name: 'Jane N.',
      slug: 'jane-n',
      title: 'Volunteer for 5 years',
      description: 'Jane leads our volunteer training programs, sharing valuable skills with new team members.',
      linkedin: 'https://www.linkedin.com/in/jose-ruiz-7b1b1b1b1/',
   }
   // {
   //    Id: '4',
   //    AtImg: atimg4,
   //    name: 'Oncon Lucy',
   //    slug: 'Oncon-Lucy',
   //    title: 'Dedicated Volunteer',
   //    description: 'Oncon is passionate about nutrition and provides vital dietary advice for our food packages.',
   //    linkedin: 'https://www.linkedin.com/in/oncon-lucy-7b1b1b1b1/',
   // },
   // {
   //    Id: '5',
   //    AtImg: atimg5,
   //    name: 'Jane Cooper',
   //    slug: 'Jane-Cooper',
   //    title: 'Dedicated Volunteer',
   //    description: 'Jane, with her warm smile, is the face of our community engagement efforts.',
   //    linkedin: 'https://www.linkedin.com/in/jane-cooper-7b1b1b1b1/',
   // },
   // {
   //    Id: '6',
   //    AtImg: atimg6,
   //    name: 'Michel Jhon',
   //    slug: 'Michel-jhon',
   //    title: 'Dedicated Volunteer',
   //    description: 'Michel’s expertise in sustainable farming practices has transformed our food collection approach.',
   //    linkedin: 'https://www.linkedin.com/in/michel-jhon-7b1b1b1b1/',
   // }
]

export default Team;
