import React, { useState } from "react";
import { Link } from "react-router-dom";

const ShopProduct = ({ products }) => {
    const [displayProducts, setDisplayProducts] = useState(products.slice(0, 6));
    const [next, setNext] = useState(6);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const loadMoreProducts = () => {
        const nextIndex = next + 6;
        const nextProducts = products.slice(next, nextIndex);
        setDisplayProducts(displayProducts.concat(nextProducts));
        setNext(nextIndex);
    };

    return (
        <>
            <style>
                {`
                    .img-holder:hover::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(38, 140, 67, 0.2); /* Green overlay */
                    }
                    .img-cart {
                        position: relative;
                    }
                    .img-holder img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                    .load-more {
                        text-align: center;
                        margin-top: 20px;
                    }
                `}
            </style>
            <div>
                <div className="shop-grids">
                    {displayProducts.map((product, index) => (
                        <div className="grid" key={index}>
                            <div className="img-cart">
                                <div className="img-holder">
                                    <Link onClick={ClickHandler} to={`/product-single/${product.slug}`}>
                                        <img src={product.proImg} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {next < products.length && (
                    <div className="load-more">
                        <button
                            className="theme-btn"
                            title="Load More"
                            onClick={loadMoreProducts}
                            style={{ padding: "10px 20px", cursor: "pointer" }} // Example inline styles
                        >
                            Load More
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default ShopProduct;
