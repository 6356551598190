import {createClient} from '@sanity/client'
// Import using ESM URL imports in environments that supports it:
// import {createClient} from 'https://esm.sh/@sanity/client'

export const client = createClient({
  projectId: '8obl0wbw',
  dataset: 'production',
  useCdn: true, 
  apiVersion: '2024-06-28',
  
})

// uses GROQ to query content: https://www.sanity.io/docs/groq
export async function getReports() {
  const reports = await client.fetch('*[_type == "report"]')
  return reports
}