import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo-white.svg';

const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault();
    }

    return (
        <footer className="site-footer">
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xl-3 col-lg-3 col-md-6 col-12">
                            <div className="widget about-widget">
                                <div className="widget-title">
                                    <h3>
                                        {/* Here the logo size is set to 50% */}
                                        <img src={Logo} alt="" style={{ width: "50%" }} />
                                    </h3>
                                </div>
                                <p> Whether you're looking to donate, volunteer, or inquire about our services, we're here to help. Your support makes our mission possible.</p>
                                <div className="social-icons">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/rosifoodcollective" target="_blank" rel="noopener noreferrer" onClick={ClickHandler}>
                                                <i className="ti-facebook"></i>
                                            </a>
                                        </li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-linkedin"></i></Link></li>
                                        <li><Link onClick={ClickHandler} to="/"><i className="ti-pinterest"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-3 col-lg-3 col-md-6 col-12">
                            <div className="widget contact-widget service-link-widget">
                                <div className="widget-title">
                                    <h3>Address Location</h3>
                                </div>
                                <ul>
                                    <li><span>319 Kittredge Street Roslindale, MA 02131</span></li>
                                    <li>
                                    <span>Phone: </span> 
                                    {/* Add inline styles for color and text-decoration */}
                                    <a href="tel:+18578290111" style={{ color: 'white', textDecoration: 'none' }}>857 - 829 - 0111</a>
                                   </li>
                                    <li><span>Email:</span> RosiFoodCollective@gmail.com</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-xl-3 col-lg-3 col-md-6 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Quick Links</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/donate">Donate</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/team">Meet team</Link></li>
                                </ul>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/contact">Privacy Policy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/testimonials">Testimonials</Link></li>
                                    <li><Link onClick={ClickHandler} to="/team">Team</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="separator"></div>
                        <div className="col col-xs-12">
                            <p>Copyright &copy; 2024 Roslindale. All rights reserved</p>
                            <div className="extra-link">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/contact">Privacy & Policy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Terms and Conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
