import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";

import Footer from "../../components/footer/Footer";
import Team from "../../api/Team";
import Logo from "../../images/logo.png";

const TeamSinglePage = (props) => {
  const { slug } = useParams();

  const TeamDetails = Team.find((item) => item.slug === slug);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Navbar hclass={"header-style-1"} Logo={Logo} btnClass={"theme-btn-s1"} />
      <PageTitle pageTitle={TeamDetails.name} pagesub="Team" />
      <section className="team-sigle-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-4 col-12">
              <div className="team-single-sidebar">
                <div className="widget attorney-widget">
                  <h3>Our team</h3>
                  <ul>
                    {Team.map((attorney, aitem) => (
                      <li key={aitem}>
                        <Link
                          onClick={ClickHandler}
                          to={`/team-single/${attorney.slug}`}
                        >
                          {attorney.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-lg-8 col-12">
              <div className="attorney-single-content">
                <div className="attorney">
                  <div
                    className="img-holder"
                    style={{ maxWidth: "400px", margin: "auto" }}
                  >
                    <img src={TeamDetails.AtImg} alt="" />
                  </div>
                  <div className="attorney-single-info">
                    <div className="info ">
                      <h3>{TeamDetails.name}</h3>
                      <span>{TeamDetails.title}</span>
                      {/* <ul>
                                                <li><i className="ti-mobile"></i><span>Phone: </span>658-85851-8747</li>
                                                <li><i className="ti-email"></i><span>Email: </span>demo@hotmail.com</li>
                                                <li><i className="ti-timer"></i><span>Experience: </span>11 Years</li>
                                                <li><i className="ti-location-pin"></i><span>Address: </span>35 jain deow street, bang dreen home</li>
                                            </ul> */}
                    </div>
                    <div className="social">
                      {TeamDetails.linkedin && (
                        <a
                          href={TeamDetails.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="ti-linkedin"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="attorney-details">
                  <h2>About Me</h2>
                  <p>{TeamDetails.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default TeamSinglePage;
