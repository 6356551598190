import cimg1 from '../images/causes/img-1.jpg'
import cimg2 from '../images/causes/img-2.jpg'
import cimg3 from '../images/causes/img-3.jpg'
import eimg4 from '../images/causes/img-4.jpg'
import eimg5 from '../images/causes/img-5.jpg'

const Causes = [
    {
        id: '1',
        cImg: cimg1,
        process: '65',
        thumb: 'Nutrition',
        cTitle: 'Empowering Local Farmers for Sustainable Food Production',
        slug: 'Sustainable-Food',
        Goal: '3,000.00',
        Raised: '2,000.00',
        authorName: 'Laura Faiary',
    },
    {
        id: '2',
        cImg: cimg2,
        process: '75',
        thumb: 'Food Security',
        cTitle: 'Supporting Urban Food Gardens for Community Resilience',
        slug: 'Urban-Food-Gardens',
        Goal: '3,000.00',
        Raised: '2,000.00',
        authorName: 'Laura Faiary',
    },
    {
        id: '3',
        cImg: cimg3,
        process: '85',
        thumb: 'Education',
        cTitle: 'Educational Programs on Nutrition and Healthy Eating',
        slug: 'Nutrition-Education',
        Goal: '3,000.00',
        Raised: '2,000.00',
        authorName: 'Laura Faiary',
    },
    {
        id: '4',
        cImg: eimg4,
        process: '95',
        thumb: 'Community Support',
        cTitle: 'Emergency Food Assistance for Disaster-Stricken Areas',
        slug: 'Emergency-Food-Assistance',
        Goal: '3,000.00',
        Raised: '2,000.00',
        authorName: 'Laura Faiary',
    },
    {
        id: '5',
        cImg: eimg5,
        process: '55',
        thumb: 'Sustainability',
        cTitle: 'Promoting Zero-Waste in Food Consumption and Production',
        slug: 'Zero-Waste-Food',
        Goal: '3,000.00',
        Raised: '2,000.00',
        authorName: 'Laura Faiary',
    },
    {
        id: '6',
        cImg: cimg3,
        process: '35',
        thumb: 'Advocacy',
        cTitle: 'Advocating for Policy Change to Fight Food Insecurity',
        slug: 'Policy-Change-Food-Insecurity',
        Goal: '3,000.00',
        Raised: '2,000.00',
        authorName: 'Laura Faiary',
    },
]

export default Causes;
